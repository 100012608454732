import React from "react";
import './styles.scss'
import AboutGif from "../gifs/about.gif";
import AboutPng from "../imgs/AboutImg.png";
import {useInView} from "react-intersection-observer";

const AboutAnimationGif = () => {
    const {ref: visionRef, inView: isVisible, entry} = useInView({
        triggerOnce: true,
        threshold: 0.2,
    })

    return (
            <div  ref={visionRef} className='about_animations_wrapper'>
                <img className='about_animation_gif' src={AboutGif} alt=""/>
                <img className={` ${isVisible ? 'about_animation_png' : 'displaynone' }`} src={AboutPng} alt=""/>
            </div>
    )
}

export default AboutAnimationGif
