import React, {useState} from "react";
import AboutAnimationGif from "../../assets/gifAnimations/AboutAnimationGif";
import TimeAnimationGif from "../../assets/gifAnimations/TimeAnimationGif";
import ExploreAnimationGif from "../../assets/gifAnimations/ExploreAnimationGif";
import ExploreAnimations from "../../assets/animateImg/ExploreAnimations/ExploreAnimations";


const Main = ({eventLink}) => {
    console.log(eventLink)
     return (
        <div>
            <ExploreAnimationGif />
            <ExploreAnimations/>
        </div>

    )
}
export default Main
