import React from "react";
import './styles.scss'
import ExploreGif from "../gifs/explore.gif";
import ExplorePng from "../imgs/ExploreImg.png";
import {useInView} from "react-intersection-observer";

const ExploreAnimationGif = () => {
    const {ref: visionRef, inView: isVisible, entry} = useInView({
        triggerOnce: true,
        threshold: 0.2,
    })

    return (
        <div  ref={visionRef} className='explore_animations_wrapper'>
            <img className={`${isVisible ? 'gif_none' : ''}`} src={ExploreGif} alt=""/>
            <img className={` ${isVisible ? 'explore_animation_png' : 'displaynone' }`} src={ExplorePng} alt=""/>
        </div>
    )
}
export default ExploreAnimationGif
