import React from "react";
import './styles.scss'
import TimeGif from "../gifs/time.gif";
import TimePng from "../imgs/TimeImg.png";
import {useInView} from "react-intersection-observer";

const TimeAnimationGif = () => {
    const {ref: visionRef, inView: isVisible, entry} = useInView({
        triggerOnce: true,
        threshold: 0.2,
    })
    return (
        <div ref={visionRef} className='time_animations_wrapper'>
            <img className='time_animation_gif' src={TimeGif} alt=""/>
            <img className={` ${isVisible ? 'time_animation_png' : 'displaynone' }`} src={TimePng} alt=""/>
        </div>)
}

export default TimeAnimationGif
